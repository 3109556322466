import { Box, Heading } from "grommet";
import React, { useState } from "react";
import ContactSelect, { ContactSelectV2 } from "../../../contacts/contact_modal/client";
import CustomInput from "../../../elements/custom_input";
import Line from "../../../elements/line_container";
import CustomSelect from "../../../elements/select_fill";
import { Checkmark } from "grommet-icons";
import PrimaryButton from "../../../elements/primary_button";

import { getEmprunteurHorslignesById } from "../../../../services/emprunteur_horsligne_service";

const onAmountChanged = (event, callback, showError, callbackNoChanged) => {
  if (!event.target.value) {
    callback(0);
    return;
  }
  event.target.value = event.target.value
    .replace(/[^0-9.,]/g, "")
    .replace(/,/g, ".")
    .replace(/(\..*?)\..*/g, "$1");
  if (event.target.value.slice(-1) === ".") {
    // skip callback to next step
    callbackNoChanged(event.target.value);
    return;
  }
  const _arrA = event.target.value.split(".");
  if (_arrA.length > 1) {
    if (_arrA[1].replace(/0/g, "") === "") {
      // skip callback to next step
      callbackNoChanged(event.target.value);
      return;
    }
  }
  const value = parseFloat(event.target.value || "0");
  if (isNaN(value)) {
    showError("Valeur non valid");
    return;
  } else {
    callback(value);
  }
};

const fractionnementOptions = () => {
  var _array = [];
  for (let index = 1; index < 100; index++) {
    _array.push(index);
  }
  return _array;
};

const PayDossier = ({
  devis = {},
  updateDevis,
  isModeContrat,
  typesContrat,
  goCheckout,
  showMessage,
}) => {
  const [isLoading] = useState(false);

  console.log("updateDevis = ", devis);
  return (
    <Box
      gap="small"
      width="xlarge"
      alignSelf="center"
      align="center"
      pad="small"
      justify="center"
    >
      <Box justify="center" alignSelf="center">
        <Heading level={3} textAlign="center">
          {`Paiement frais de dossier`}
        </Heading>
      </Box>

      <Box width="large" gap="medium" margin="medium">
        <Box gap="xsmall">
          <ContactSelectV2
            contact={devis.client || {}}
            updateContact={updateDevis}
            addressIncluded={false}
            birthDayIncluded={false}
            withCreateNew={false}
          />
          <Line
            leftLabel="Type Contrat"
            rightChildren={
              <CustomSelect
                style={{ width: "100%" }}
                placeholder="Type Contrat"
                options={typesContrat ? typesContrat.map((e) => e.label) : []}
                value={devis.type_contrat}
                onChange={({ option }) => updateDevis("type_contrat", option)}
              />
            }
          />

          {devis.type_contrat !== "Emprunteur" && (
            <Line
              leftLabel="Numéro de dossier"
              rightChildren={
                <CustomInput
                  onChange={(event) => {
                    updateDevis("numero_dossier", event.target.value);
                  }}
                  value={devis.numero_dossier || ""}
                />
              }
            />
          )}

          {devis.type_contrat === "Emprunteur" && (
            <Line
              leftLabel="ID interne"
              rightChildren={
                <CustomInput
                  onChange={(event) => {
                    getEmprunteurHorslignesById(event.target.value)
                      .then((e) => {
                        if (e.data) {
                          updateDevis(
                            "frais_dossier",
                            e.data.fractionnement_frais_dossier * e.data.nombre_de_prelevement
                          );
                          updateDevis(
                            "fractionnement",
                            e.data.nombre_de_prelevement
                          );
                        }
                      })
                      .catch((e) => {
                        console.log("Not found");
                      });
                    updateDevis("objectId", event.target.value);
                    updateDevis("numero_dossier", event.target.value);
                  }}
                  value={devis.objectId || ""}
                />
              }
            />
          )}

          <Line
            leftLabel="Indiquer les frais de dossier"
            rightChildren={
              <CustomInput
                value={devis.frais_dossier}
                onChange={(event) => {
                  onAmountChanged(
                    event,
                    (amount) => {
                      updateDevis("frais_dossier", amount);
                    },
                    showMessage,
                    (nochanged) => {
                      updateDevis("frais_dossier", nochanged);
                    }
                  );
                }}
              />
            }
          />

          <Line
            leftLabel="Fractionnement des frais de dossiers"
            rightChildren={
              <CustomSelect
                style={{ width: "100%" }}
                placeholder="Fractionnement"
                options={fractionnementOptions()}
                value={devis.fractionnement}
                onChange={({ option }) => {
                  if (option === 1) {
                    updateDevis("delayed_1_month", false);
                  }
                  updateDevis("fractionnement", option);
                }}
              />
            }
          />
          {/* {devis.fractionnement === 1 && (
            <Line
              rightChildren={<Text>différé possible à partir de 2 mois</Text>}
            />
          )}
          <Line
            leftLabel="Le client souhaite-t-il différer son paiement ?"
            rightChildren={
              <ToggleButton
                disabled={devis.fractionnement === 1}
                groups={[
                  {
                    label: "Oui 1 mois de différé",
                    selected: delayed1month === true,
                    onClick: () => {
                      setDelayed1month(true);
                      updateDevis("delayed_1_month", true);
                    },
                  },
                  {
                    label: "Non",
                    selected: delayed1month === false,
                    onClick: () => {
                      setDelayed1month(false);
                      updateDevis("delayed_1_month", false);
                    },
                  },
                ]}
              />
            }
          /> */}

          <Box margin="medium" align="center" justify="center">
            <div
              dangerouslySetInnerHTML={{
                __html: `${devis.message}`,
              }}
            />
          </Box>
          <Box align="center" margin="medium">
            <PrimaryButton
              disabled={isLoading}
              label={`Valider`}
              icon={<Checkmark />}
              onClick={() => {
                goCheckout(devis);
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PayDossier;
