import React from "react";
import { Box, Image, Menu, ResponsiveContext, Text } from "grommet";
import PayDossier from "../components/ventes/trusti-mhs/trusti_mh/pay_dossier";
import mainLogo from "../images/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../components/elements/layout";
import { User } from "grommet-icons";
import { getAllTypeContrat } from "../services/contact_service";
import { sendPayFraisDossier } from "../services/trusti_mh_service";

class PaiementPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: this.props.contact || {},
      devis: { fractionnement: 1, message: "" },
      contratTypes: [],
      embedded: props.embedded
    };
  }

  showMessage = (message, messageType) => {
    messageType = messageType || "info";
    toast[messageType](message);
  };

  updateDevis = (field, _value) => {
    let devis = this.state.devis;
    devis[field] = _value;

    if (devis.frais_dossier) {
      var _msg = "Les frais de dossier sont de <b>";
      var value = parseFloat(devis.frais_dossier || "0");
      if (isNaN(value)) {
        value = 0;
      }

      if (devis.fractionnement) {
        if (devis.fractionnement > 1) {
          // all in once
          _msg +=
            (value / devis.fractionnement).toFixed(2) +
            " € / mois</b>, pendant " +
            devis.fractionnement +
            " mois soit un total de <b>" +
            value +
            " €</b>";
        } else {
          // all in once
          _msg += value + " €</b>";
        }
      } else {
        // all in once
        _msg += value + " €</b>";
      }

      if (devis.delayed_1_month) {
        _msg += ", le prélèvement débute dans 1 mois.";
      }
      devis.message = _msg;
    }
    this.setState({ devis: Object.assign({}, devis) });
  };

  sendEmailPayment = async () => {
    sendPayFraisDossier(this.state.devis)
      .then((res) =>
        this.showMessage("Email a été envoyé", "success")
      )
      .catch((err) =>
        this.showMessage("Echec d'envoyer formulaire", "error")
      );
  };

  componentDidMount() {
    getAllTypeContrat()
      .then((res) => {
        let _allComs = res.data?.options;
        console.log("_allComs =", _allComs);
        this.setState({ contratTypes: _allComs });
      })
      .catch((err) => console.log("err = ", err));
  }

  render() {
    return (
      <Layout style={{ justifyContent: "center", alignContent: "center" }}>
        <ResponsiveContext.Consumer>
          {(size) => (
            <Box
              as="main"
              flex
              overflow="auto"
              style={{ width: "100%" }}
              direction="column"
              alignContent="center"
              alignSelf="center"
            >
              {!this.props.embedded && (
                <>
              <Box
                justify="center"
                style={{ position: "absolute", left: 0, top: 0, height: 80 }}
              >
                <Image src={mainLogo} width={300} />
              </Box>
              
              <Box
                direction="row"
                alignContent="between"
                style={{
                  backgroundColor: "#F0F0F0",
                  height: 80,
                  width: "100%",
                }}
              >
                <Box
                  direction="row"
                  gap="small"
                  justify="center"
                  align="center"
                  alignContent="center"
                  style={{ position: "absolute", right: 0, top: 0, height: 80 }}
                >
                  <Text size="small" textAlign="end">
                    Bonjour {this?.state?.user?.prenom}
                  </Text>
                  <Menu
                    icon={<User />}
                    items={[{ label: "Logout", onClick: this.logout }]}
                  />
                </Box>
              </Box>
              </>
              )}

              <PayDossier
                showMessage={this.showMessage}
                devis={this.state.devis}
                updateDevis={this.updateDevis}
                typesContrat={this.state.contratTypes}
                goCheckout={this.sendEmailPayment}
              />
            </Box>
          )}
        </ResponsiveContext.Consumer>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Layout>
    );
  }
}
export default PaiementPage;
